import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import MainContainer from '../pages/MainContainer/MainContainer';
import SubContainer from '../pages/SubContainer/SubContainer';

const IndexApp = () => {
    return (
        <div>
            <Router>
                <Routes>
                    <Route exact path='/' element={<MainContainer/>}/>
                    <Route exact path='*' element={<MainContainer/>}/>
                    <Route exact path='/abogados/' element={<SubContainer/>}/>
                </Routes>
            </Router>
        </div>
    );
}

export default IndexApp;
