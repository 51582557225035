import { CollapSedInfo } from './components';
import { lawyersData } from '../../../data/lawyers-data';
import './styles/SubSecondSection.css';
import { useRef } from 'react';

const SubSecondSection = () => {

    const parentRef = useRef();

    return (
        <section className="subsecond-section-container" id='abogados' ref={parentRef}>
            <h2>Abogados</h2>
            { lawyersData && lawyersData.map( l => <CollapSedInfo key={l.name} lawyer={l} refP={parentRef}/> ) }
        </section>
    );
}

export default SubSecondSection;
