import { FirstSection, SubSecondSection } from './index';
import { FourthSection } from '../MainContainer'
import Footer from '../Footer/Footer';
import './styles/sub-container.css';

const SubContainer = () => {
    return (
        <div className="subcontainer-root-container">
            <FirstSection/>
            <SubSecondSection/>
            <FourthSection/>
            <Footer isSecondPage={true}/>
        </div>
    );
}

export default SubContainer;
