import DarkLegality from '../../../assets/dark-legality.png';
import { HiPhone } from "react-icons/hi";
import { MdLocationPin } from 'react-icons/md'
import './styles/FourthSection.css';
import GoogleMapComponent from '../../GoogleMaps/GoogleMap';

const FourthSection = () => {
    return (
        <div className="fourth-section-main-container" id='contacto'>
            <div className='fourth-section-title-container'>
                <h2>Contacto</h2>
            </div>
            <div className='fourth-section-wrapper'>
                <div className='fourth-section-contact-info'>
                    <div className='light-logo-container'>
                        <img src={DarkLegality} alt='Logo LI'/>
                    </div>
                    <div className='location-container'>
                        <MdLocationPin className='icon-location'/>
                        <p className='location-text'>
                            Prolongación Alfonso Reyes #2612, Edificio Connexity, Piso 9 
                            Despacho 906, Col. Del Paseo Residencial, Monterrey, N.L.
                        </p>
                    </div>
                    <div className='location-container'>
                        <HiPhone className='icon-location'/>
                        <p className='location-text'>
                            (81) 1353 1110
                        </p>
                    </div>
                    
                </div>

                <div className='fourth-section-contact-info map-info'>
                    <GoogleMapComponent/>
                </div>
            </div>
        </div>
    );
}

export default FourthSection;
