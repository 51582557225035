import legality from '../../../assets/light-legality.png';
import Header from "../../Header/Header";
import './styles/FirstSection.css';

const FirstSection = () => {
    
    return (
        <div className="first-section-main-container" id="inicio">
            <Header/>
            <div className="logo-first-section-container">
                <img src={legality} alt="Logo legalidad integral"/>
            </div>
            <div className="text-container-first-section">
                <p className="text-first-section">Somos un despacho que desde 2007 presta servicios legales profesionales, 
                    con la finalidad de conjugar la experiencia, capacidad y especialización para satisfacer 
                    los diferentes requerimientos y necesidades de nuestros clientes, 
                    prestando solución real de conflictos y atención personalizada, apoyados en la última tecnología.
                </p>
            </div>
        </div>
    );
}

export default FirstSection;
