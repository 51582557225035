import { useSlider } from './hooks';
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi';
import './styles/ThirdSection.css';

const ThirdSection = () => {

    const [image, onActions] = useSlider();
    const { onPrevious, onNext } = onActions;


    const sliderStyles = {
        backgroundImage: `url(${image.image})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
    }

    return (
        <section className='third-section-main-container' id='servicios'>
            <div className='title-third-section-container'>
                <h2>Servicios</h2>
            </div>
            <div className='slider-third-section-container'>
                <HiChevronLeft className='arrow-icon' onClick={onPrevious}/>
                <div className='images-slider-container' style={sliderStyles}>
                    <div className='overlay-container'></div>
                    <h2>{image.title}</h2>
                    <p>{image.msg}</p>
                </div>
                <HiChevronRight className='arrow-icon' onClick={onNext}/>
            </div>
            <div className='third-section-container-text'>
                <p>La responsabilidad social es importante para LI y se ve reflejada al prestar servicios profesionales gratuitos a 
                    favor de personas de escasos recursos que no cuentan con acceso a servicios legales de calidad.
                </p>
            </div>
        </section>
    );
}

export default ThirdSection;
