import { useEffect } from "react"
import { useLocation } from "react-router-dom";
export const useScroll = () =>{

    const location = useLocation();

    useEffect(()=>{
        const section = location.hash;
       
        if(!section) return;

        const sectionDepured = section.replace('#', '');
        const element = document.getElementById(sectionDepured);
        
        if(!element) return;
        
        element.scrollIntoView();

    },[location.hash]);


}