import { useState } from "react"

export const useMobileMenu = () =>{
    const [ clicked, setClicked ] = useState(false);


    const onToggleMenu = () =>{
        setClicked(!clicked);
    }

    return[ clicked, onToggleMenu ];
}