import './styles/footer.css';

const Footer = ({ isSecondPage = false}) => {
    return (
        <div className="footer-root-container">
            <div className='footer-wrapper'>
                <div className='menu-footer-options'>
                    <ul>
                        <li><a href='#inicio'>Inicio</a></li>
                        <li><a href="#abogados">Abogados</a></li>
                        <li> <a href={ !isSecondPage ? "#servicios" : '/#servicios' }>Servicios</a></li>
                        <li><a href="#contacto">Contacto</a></li>
                        <li> <a href={ !isSecondPage ? "#ligas-interes" : '/#ligas-interes'}>Ligas de interés</a></li>
                    </ul>
                </div>
                <div className='legalidad-integral-copy'>
                    <p> &copy; Legalidad Integral Abogados </p>
                    <p>Todos los derechos reservados</p>
                </div>
            </div>
        </div>
        
    );
}

export default Footer;
