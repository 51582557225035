import { HiArrowNarrowRight } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import SecondSectionImg from '../../../assets/second-section.jpg';

import './styles/second-section.css';

const SecondSection = () => {

    const navigate = useNavigate();

    return (
        <section className="second-section-main-container" id="abogados">
            <div className="max-container-wrapper">
                <div className='second-section-img-container'>
                    <img src={SecondSectionImg} alt='Second section'/>
                </div>
                <div className='second-section-text-button-container'>
                    <h2>Nuestros abogados</h2>
                    <button onClick={ () => navigate('/abogados/')}>Conócenos <span><HiArrowNarrowRight/></span></button>
                </div>
            </div>
            
            <div className="text-second-section-container">
                <p>En Legalidad Integral Abogados, nuestro compromiso es hacia el cliente, 
                    brindándole servicios profesionales, objetivos, actualizados y estratégicos 
                    sobre los asuntos donde se encuentre involucrado el patrimonio, la familia 
                    y la libertad de las personas.
                </p>
            </div>
        </section>
    );
}

export default SecondSection;
