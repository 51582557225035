import { HiChevronUp } from 'react-icons/hi';
import { useCollapsed  } from '../hooks';
import '../styles/SubSecondSection.css';

const CollapSedInfo = ({ lawyer, refP }) => {

    const [ collapsed, onCollapsed ] = useCollapsed(refP);
    

    return (
        <div className="collapsed-info-container-root" >
            <span className={ collapsed ? 'span-item-collapsed-info' : 'span-item-collapsed-info-uncollapsed'} onClick={onCollapsed}>
                {lawyer?.name}
            </span>
            <div className='information-lawyer-container' style={{ display: collapsed ? 'none' : 'flex' }}>
                <div className='left-info'>

                    { lawyer.eduaction.length !==0 ? <h3>Estudios profesionales y de posgrado:</h3> : null}
                        <ul>
                            {lawyer ? lawyer.eduaction.map( x => <li key={x}> {x} </li>) : null}
                        </ul>
                    { lawyer.additionalEducation.length !== 0 ?  <h3>Estudios adicionales:</h3> : null}
                        <ul>
                            {lawyer !== 0 ? lawyer.additionalEducation.map( x => <li key={x}> {x} </li>) : null}
                        </ul>
                </div>

                <div className='right-info'>

                    { lawyer.papers.length !== 0 ?  <h3>Publicaciones:</h3> : null}
                    { lawyer.papers.length !== 0 ? 
                        <ul>
                            {lawyer ? lawyer.papers.map( x => <li key={x}> {x} </li>) : null}
                        </ul>
                        :
                        null
                    }
                        
                    
                    { lawyer.classActivity.length !== 0 ? <h3>Actividad docente:</h3> : null}
                    { lawyer.classActivity.length !== 0 ? 
                        <ul>
                            {lawyer  ? lawyer.classActivity.map( x => <li key={x}> {x} </li>) : null}
                        </ul>
                        : 
                        null
                    }
                        

                    { lawyer.practiceArea.length !== 0 ?  <h3>Áreas de práctica:</h3> : null}
                    { lawyer.practiceArea.length !== 0 ? 
                        <ul>
                            {lawyer !== 0 ? lawyer.practiceArea.map( x => <li key={x}> {x} </li>) : null}
                        </ul>
                        : 
                        null
                    }
                        
                </div>
                
            </div>
            <div className='arrow-container-close-info' style={{ display: collapsed ? 'none' : 'flex'}}>
                <HiChevronUp className='arrow-close-icon' onClick={onCollapsed}/>
            </div>
        </div>
    );
}

export default CollapSedInfo;
