import './styles/FifthSection.css';

const FifthSection = () => {
    return (
        <section className='fifth-section-main-container' id='ligas-interes'>
            <div className='title-fifth-section-container'>
                <h2>Ligas de Interés</h2>
            </div>
            <div className='ligas-body-container'>
                <div className='ligas-container'>
                    <h3>Suprema Corte de Justicia de la Nación</h3>
                    <a href='https://www.scjn.gob.mx/' target={'_blank'} rel={'noreferrer'}>www.scjn.gob.mx</a>

                    <h3>Consejo de la Judicatura</h3>
                    <a href='https://www.cjf.gob.mx/' target={'_blank'} rel={'noreferrer'}>www.cjf.gob.mx</a>

                    <h3>Cámara de Diputados</h3>
                    <a href='https://web.diputados.gob.mx/inicio' target={'_blank'} rel={'noreferrer'}>www.diputados.gob.mx</a>

                </div>

                <div className='ligas-container'>
                    <h3>Senado de la República</h3>
                    <a href='https://www.senado.gob.mx/' target={'_blank'} rel={'noreferrer'}>www.senado.gob.mx</a>

                    <h3>Congreso del Estado de N.L.</h3>
                    <a href='https://www.hcnl.gob.mx/' target={'_blank'} rel={'noreferrer'}>www.hcnl.gob.mx</a>

                    <h3>Poder Judicial del Estado de N.L.</h3>
                    <a href='https://www.pjenl.gob.mx/' target={'_blank'} rel={'noreferrer'}>www.pjenl.gob.mx</a>
                    
                </div>
            </div>
        </section>
    );
}

export default FifthSection;
