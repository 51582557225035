import './styles/GoogleMap.css';

const GoogleMapComponent = () => {

    return (
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7934.1549767953675!2d-100.30660623996684!3d25.62690855374851!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8662bfaa9657c271%3A0xd2b273b42f4c1ca3!2sEdificio%20Connexity%2C%20Av.%20Alfonso%20Reyes%202612%2C%20Del%20Paseo%20Residencial%2C%2064920%20Monterrey%2C%20N.L.!5e0!3m2!1sen!2smx!4v1658535915488!5m2!1sen!2smx" title='map' className='map-container'></iframe>

    );
}

export default GoogleMapComponent;
