import legality from '../../../assets/light-legality.png';
import Header from '../../Header/Header';
import './styles/FirstSectionSub.css';

const FirstSection = () => {
    
    return (
        <section className="first-section-subcontainer" id='inicio'>
            <Header isSecondContain={true}/>
            <div className="logo-first-section-container second-section-logo">
                <img src={legality} alt="Logo legalidad integral"/>
            </div>
            <div className="text-container-second-section">
                <h2>Nuestros abogados</h2>
                <p className="text-second-section">Somos un despacho que desde 2007 presta servicios legales profesionales, 
                    con la finalidad de conjugar la experiencia, capacidad y especialización para satisfacer 
                    los diferentes requerimientos y necesidades de nuestros clientes, 
                    prestando solución real de conflictos y atención personalizada, apoyados en la última tecnología.
                </p>
            </div>
        </section>
    );
}

export default FirstSection;
