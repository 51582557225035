import Footer from '../Footer/Footer';
import { FirstSection, SecondSection, ThirdSection, FourthSection, FifthSection } from './index';
import { useScroll } from './hooks/useScroll';
import './styles/main-container.css';

const MainContainer = () => {
    
    useScroll();

    return (
        <div className="integral-legality-main-container">
            <FirstSection/>
            <SecondSection/>
            <ThirdSection/>
            <FourthSection/>
            <FifthSection/>
            <Footer/>
        </div>
    );
}

export default MainContainer;
