import { useState } from "react"

export const useCollapsed = (refP) =>{
    
    const [ collapsed, setCollapsed ] = useState(true);

    const onCollapsed = () =>{
        if(!collapsed) refP.current.scrollIntoView();
        setCollapsed(!collapsed);

    } 


    return [ collapsed, onCollapsed ];
}