export const lawyersData = [

    {
        name: 'Lic. Samuel Rubio Fernández',
        eduaction: [
            'Licenciatura en Derecho en la Facultad Libre de Derecho de Monterrey A.C. Cédula Profesional: 2476248.',
            'Posgrado, Maestría en Ciencias Penales en la Universidad Autónoma de Nuevo León. Cédula Profesional: 4083845',
        ],
        additionalEducation: [
            '1992 a 1993, Vicepresidente de la Mesa Directiva de la Sociedad de Alumnos de la Facultad Libre de Derecho.',
            'Simposium, Septiembre de 1991, Reestructuración de la Justicia.',
            'Simposium, Octubre de 1993, Retos y Preceptivas del Abogado',
            'Curso de Especialización Judicial expedido por el Instituto de la Judicatura de la Suprema Corte de Justicia de la Nación, extensión Nuevo León.',
            'Seminario sobre Dictámenes Periciales.',
            'Curso de Posgrado sobre Derecho Penal impartido por la Universidad de Salamanca en la ciudad de Salamanca, España.',
        ],
        papers: ['“El Ejercicio de la Garantía de Defensa en los Plazos Oscuros del Procedimiento Penal”, publicado por la editorial Porrúa, en Junio de 2004.'],
        classActivity: [],
        practiceArea: []
    },
    {
        name: 'Lic. Blanca Fernández Corona',
        eduaction: [
            'Instituto de la Judicatura Federal. (Monterrey, Nuevo León, México)',
            'Curso Básico de Formación y Preparación de Secretarios del Poder Judicial de la Federación.',
            'Estudios de Especialidad: Ciclo escolar 2017.',
            'Patente aprobada.',
            'Universidad Autónoma de Nuevo León. (Monterrey, Nuevo León, México)',
            'Posgrado en Derecho Penal en el Sistema Penal Acusatorio: Agosto 2015 a Mayo 2017.',
            'Tecnológico de Monterrey (Monterrey, Nuevo León, México)',
            'Licenciatura en Derecho: 2006–2010.',
            'Miembro activo de la Sociedad de Alumnos de Derechos (SALED): 2006-2010.',
            'Miembro de la Fraternidad Internacional de Derecho PHI DELTA PHI: 2009 en adelante.' 
        
        ],
        additionalEducation: [
            'Comisión Ejecutiva para la Implementación de la Reforma del Sistema de Justicia Penal en el Estado de Nuevo León. (Monterrey, Nuevo León). Certificado: Enero 2013.',
            'Tecnológico de Monterrey; Centro de Desarrollo para la Abogacía en el Litigio Oral. (Monterrey, Nuevo León, México)',
            'Diplomado en el Proceso Penal Acusatorio: Marzo a Junio de 2012.',
            'Facultad Libre de Derecho de Monterrey, (Monterrey, Nuevo León, México)',
            'Nuevo Sistema de Justicia Penal Acusatorio en México: Marzo de 2012.',
            'Universidad Pontificia Comillas de Madrid. (Madrid, España)',
            'Especialidad en Derecho Internacional con enfoque a la protección de los Derechos Humanos: Agosto a Diciembre de 2008.'
        ],
        papers: [],
        classActivity: [],
        practiceArea: ['Litigio penal patrimonial y amparo.']
    },
    {
        name: 'Lic. Bárbara Paola Garza García',
        eduaction: [
            'Licenciatura en Derecho, Universidad de Monterrey, en Monterrey, Nuevo León, México, Diciembre de 2019.'
        ],
        additionalEducation: [
            'Taller de Juicio Oral Penal, Universidad de Monterrey, 2016.',
            'CONCURSO INTERUNIVERSITARIO DE ORATORIA, Universidad de Monterrey, 2016.',
            'TALLER DE ORATORIA Y ARGUMENTACIÓN, Universidad de Monterrey, 2016.',
            'DIPLOMADO JUICIO DE AMPARO, Casa de la Cultura Jurídica, 2021.',
        ],
        papers: [],
        classActivity: [],
        practiceArea: ['Litigio penal patrimonial, civil y amparo.']
    },
    {
        name: 'Lic. Bernardo Nasta Treviño',
        eduaction: ['Licenciado en Derecho, Facultad de Derecho Y Criminología, Universidad Autónoma de Nuevo León, 2017.'],
        additionalEducation: [
            'Diplomado en Juicios Orales en materia Civil y Mercantil, en la Universidad Intercontinental de la Ciudad de México, 2021.'
        ],
        papers: [],
        classActivity: [],
        practiceArea: [
            'Litigio civil, mercantil, y amparo.'
        ]
    },
    {
        name: 'Lic. Joaquín Gutiérrez Lozano',
        eduaction: [
            'Licenciado en Derecho, Instituto Tecnológico y de Estudios Superiores de Monterrey ITESM, Monterrey, Nuevo León, México, 2014.',
            'Maestría en Derecho Penal con Enfoque en el Sistema Penal Acusatorio, Facultad de Derecho y Criminología, Universidad Autónoma de Nuevo León, Monterrey, Nuevo León, México, 2019.',
        ],
        additionalEducation: [
            'Taller Internacional de Incorporación de Prueba Material y Documental, Alianza Interamericana de Abogacía Oral, Modalidad en línea, 2021.',
            'Congreso Internacional Nuevo Modelo de Fiscalía, Instituto Nacional de Ciencias Penales INACIPE, Ciudad de México, México 2018.',
            'Diplomado en el Sistema de Justicia Penal Acusatorio, Facultad de Derecho y Criminología, Universidad Autónoma de Nuevo León, Monterrey, Nuevo León, México, 2015.',
        ],
        papers: [],
        classActivity: [],
        practiceArea: ['Litigio penal patrimonial, civil y amparo.']
    },
    {
        name: 'Lic. Ricardo Lugo Morales',
        eduaction: [
            'Licenciado en Derecho, Universidad de Monterrey, Nuevo León',
        ],
        additionalEducation: [],
        papers: [],
        classActivity: [],
        practiceArea: ['Litigio penal patrimonial, civil y amparo.']
    },
    {
        name: 'Lic. Edgar Otniel Rojas Saldaña',
        eduaction: [
            'Facultad Libre de Derecho de Monterrey A.C. (Monterrey, Nuevo León, México). Licenciatura en Derecho: 2005–2009.',
            'Tecnológico de Monterrey; Escuela de Graduados en Administración Pública y Política Pública (EGAP). Maestría en Derecho: 2013-2015.'
        ],
        additionalEducation: [],
        papers: [],
        classActivity: [],
        practiceArea: ['Especialista en asesoría jurídica y defensa en el sistema penal acusatorio en México.']
    },

];