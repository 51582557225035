import { HiMenu, HiOutlineX } from "react-icons/hi";
import { useMobileMenu } from "./hooks/useMobileMenu";

const Header = ({ isSecondContain = false }) => {

    const [ clicked, onClickMenu ] = useMobileMenu();

    return (
        <header className={ !clicked ? "header-first-section" : "header-first-section clicked-menu"}>
            <div className="desktop-menu">
                <ul>
                    <li>
                        <a href={ !isSecondContain ?  "#inicio" : "/#inicio"}>Inicio</a>
                    </li>
                    <li>
                        <a href='#abogados'>Abogados</a>
                    </li>
                    <li>
                        <a href={ !isSecondContain ? "#servicios" : '/#servicios' }>Servicios</a>
                    </li>
                    <li>
                        <a href="#contacto">Contacto</a>
                    </li>
                    <li>
                        <a href={ !isSecondContain ? "#ligas-interes" : '/#ligas-interes'}>Ligas de interés</a>
                    </li>
                </ul>
            </div>
            
            {/* Mobile Menú */}
            { !clicked ? <HiMenu className="menu-icon-first-section" onClick={onClickMenu}/>  : <HiOutlineX className="menu-icon-first-section" onClick={onClickMenu}/> } 
            <div className="header-body mobile-menu" style={{ display: clicked ? 'flex' : 'none' }}>
                <ul>
                    <li onClick={onClickMenu}>
                        <a href={ !isSecondContain ?  "#inicio" : "/#inicio"}>Inicio</a>
                    </li>
                    <li onClick={onClickMenu}>
                        <a href='#abogados'>Abogados</a>
                    </li>
                    <li onClick={onClickMenu}>
                        <a href={ !isSecondContain ? "#servicios" : '/#servicios' }>Servicios</a>
                    </li>
                    <li onClick={onClickMenu}>
                        <a href="#contacto">Contacto</a>
                    </li>
                    <li onClick={onClickMenu}>
                        <a href={ !isSecondContain ? "#ligas-interes" : '/#ligas-interes'}>Ligas de interés</a>
                    </li>
                </ul>
            </div>
        </header>
    );
}

export default Header;
