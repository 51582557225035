import { useState } from "react";
import { sliderImages } from "../../../../data/slider-imgs";

export const useSlider = () =>{

    const [ index, setIndex ] = useState(0);
    const [ image, setImage ] = useState(sliderImages[index]);

    const sliderSize = sliderImages.length;

    const onActions = {
        onPrevious: () =>{
            index === 0 ? setIndex( sliderSize - 1 ) : setIndex( index - 1 );
            setImage( sliderImages[index] )
        },
        onNext: () =>{
            index === sliderSize - 1 ? setIndex(0) : setIndex( index + 1);
            setImage( sliderImages[index] )
        }
    }

    return [image, onActions];
}