import amparo from '../assets/slider/amparo.jpg';
import contratos from '../assets/slider/contratos.jpg';
import DerechoPenal from '../assets/slider/DerechoPenal.jpg';
import litigio from '../assets/slider/litigio.jpg';

export const sliderImages = [
    {
        image: litigio,
        title: 'Litigio',
        msg: 'Contamos con litigantes experimentados y capacitados para resolver cualquier controversia de cualquier carácter para defender su patrimonio. Abarcamos estas áreas de litigio civil, mercantil y administrativo.'
    },
    {
        image: DerechoPenal,
        title: 'Derecho Penal',
        msg: 'Brindamos asesoría en derecho penal patrimonial tanto federal como del fuero común.'
    },
    {
        image: amparo,
        title:'Juicio de Amparo',
        msg: 'Con este servicio buscamos hacer reales, eficaces y prácticas las garantías individuales establecidas en la Constitución, buscando proteger de los actos de todas las autoridades sin distinción de rango, inclusive las más elevadas, cuando violen dichas garantías.'
    },
    {
        image: contratos,
        title: 'Contratos',
        msg: 'Este servicio incluye la elaboración de todo tipo de contratos desde arrendamientos, compra /venta, prestación de servicios profesionales y más.'
    },

]